<template>
  <div>
    <NavBar/>
    <div class="container mt-4 mb-4">
      <div class="row">
        <!-- Colonne de gauche avec l'image -->
        <!-- Colonne de droite avec l'accordéon -->
        <div class="col-md-6">
          <div class="mb-4 mt-4">
            <img :src="selectedArrondissement.image" class="img-fluid" alt="Image de l'arrondissement"/>
          </div>
        </div>
        <div class=" align-self-center col-md-6">
          <div v-if="selectedArrondissement">
            <h1 class="text-center mb-5">{{ selectedArrondissement.nom }}</h1>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item" v-if="selectedArrondissement.détail">
                <h2 class="accordion-header" id="headingOne">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true" aria-controls="collapseOne">
                    La ville en détail
                  </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                     data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    {{ selectedArrondissement.détail }}
                  </div>
                </div>
              </div>
              <div class="accordion-item" v-if="selectedArrondissement.environnement">
                <h2 class="accordion-header" id="headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Environnement
                  </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                     data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    {{ selectedArrondissement.environnement }}
                  </div>
                </div>
              </div>
              <div class="accordion-item" v-if="selectedArrondissement.monuments">
                <h2 class="accordion-header" id="headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Monuments
                  </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                     data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    {{ selectedArrondissement.monuments }}
                  </div>
                </div>
              </div>
              <div v-if="selectedArrondissement">
                <div class="accordion" id="accordionExample2">
                  <div class="accordion-item" v-if="selectedArrondissement.monuments2">
                    <h2 class="accordion-header" id="headingFour">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        Monuments
                      </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                         data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        {{ selectedArrondissement.monuments2 }}
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item" v-if="selectedArrondissement.espaces">
                    <h2 class="accordion-header" id="headingFive">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        Espaces
                      </button>
                    </h2>
                    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                         data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        {{ selectedArrondissement.espaces }}
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item" v-if="selectedArrondissement.conclusion">
                    <h2 class="accordion-header" id="headingSix">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        Pour finir
                      </button>
                    </h2>
                    <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingThree"
                         data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        {{ selectedArrondissement.conclusion }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    <LogementByArrondissement/>-->
    <Zone/>
    <Footer/>
  </div>
</template>


<script>
import Footer from "@/components/Footer";
import NavBar from "@/components/Navbar";
import Zone from "@/components/Zone";
/*import LogementByArrondissement from "@/components/Arrondissements/LogementsByArrondissement";*/

export default {
  name: "ArrondissementPage",
  components: {/*LogementByArrondissement,*/ Zone, Footer, NavBar},
  data() {
    return {
      arrondissements: [
        {
          id: 1,
          nom: "Paris",
          détail: "Paris, la capitale de la France, est mondialement connue pour son charme intemporel et son riche héritage culturel. La ville est découpée en 20 arrondissements, chacun avec sa propre histoire et son caractère unique. Du majestueux Louvre à la romantique Tour Eiffel, Paris offre une expérience inoubliable à chaque coin de rue.",
          environnement: "Baignée par la Seine, Paris se distingue par ses ponts emblématiques tels que le Pont Neuf, le Pont Alexandre III et le Pont des Arts. Les rives de la Seine accueillent des bouquinistes, créant une atmosphère artistique et romantique. Les larges avenues, les cafés animés et les parcs pittoresques ajoutent à la splendeur de la Ville Lumière.",
          monuments: "Paris regorge de monuments historiques, des cathédrales gothiques comme Notre-Dame-de-Paris à la majestueuse Sainte-Chapelle. L'Arc de Triomphe sur la célèbre avenue des Champs-Élysées et la place de la Concorde avec son obélisque égyptien contribuent à l'aura grandiose de la ville.",
          monuments2: "Outre ses joyaux architecturaux, Paris abrite des institutions notables telles que le Musée d'Orsay, le Centre Pompidou et l'Opéra Garnier. Des bâtiments gouvernementaux tels que l'Hôtel de Ville et le Palais Bourbon ajoutent une dimension politique à la richesse culturelle de la ville.",
          espaces: "Bien que densément peuplée, Paris offre des havres de verdure tels que le Jardin des Tuileries, le Luxembourg et le Bois de Vincennes. Ces espaces invitent les Parisiens et les visiteurs à se détendre, à flâner et à profiter de la beauté sereine au cœur de l'agitation urbaine.",
          conclusion: "En raison de son patrimoine culturel exceptionnel, Paris attire des visiteurs du monde entier. Des hôtels élégants bordent les rues, offrant un confort luxueux à ceux qui explorent les trésors de la ville, du Louvre à la Tour Eiffel en passant par Montmartre. Les incontournables incluent les musées renommés, les rives de la Seine et les délices culinaires des boulangeries locales.",
          image: "/arrondissementsdetails/eiffel2.jpg",
        },
        {
          id: 2,
          nom: "Nice",
          détail: "Nice, la perle de la Côte d'Azur, séduit par sa beauté méditerranéenne et son ambiance décontractée. La ville est réputée pour son mélange unique de glamour, d'histoire et de douceur de vivre. Des ruelles colorées du Vieux Nice aux plages ensoleillées de la Promenade des Anglais, chaque coin de Nice respire une élégance décontractée.",
          environnement: "Nichée entre la mer Méditerranée et les collines environnantes, Nice offre un cadre exceptionnel. La Baie des Anges, avec ses eaux azurées, invite à la détente tandis que les collines offrent des vues panoramiques sur la ville. Les parcs tels que le Parc du Mont Boron offrent des escapades naturelles à quelques pas du centre-ville.",
          monuments: "Nice dévoile son histoire à travers des monuments tels que la Cathédrale Saint-Nicolas, une magnifique église orthodoxe russe, et la Place Masséna, entourée de bâtiments Belle Époque. La colline du Château, bien que sans château, offre une vue imprenable sur la ville et la Méditerranée.",
          monuments2: "La ville compte également des édifices emblématiques comme l'Opéra de Nice, le Palais Lascaris et le musée Marc Chagall. Les façades colorées des bâtiments du Vieux Nice ajoutent une touche artistique à cette ville méditerranéenne.",
          espaces: "Malgré sa taille modeste, Nice propose des espaces verts charmants comme le Jardin Albert Ier et le Parc Phoenix. Les plages de galets de la Promenade des Anglais attirent les baigneurs, tandis que les terrasses des cafés offrent un endroit idéal pour profiter du soleil et de la vue sur la mer.",
          conclusion: "Nice, avec son climat ensoleillé, son atmosphère détendue et sa scène artistique florissante, attire les visiteurs du monde entier. Des hôtels de luxe bordent la Promenade des Anglais, offrant des vues spectaculaires sur la mer. Les incontournables incluent les marchés provençaux animés, la vieille ville pittoresque et les plaisirs de la cuisine méditerranéenne, faisant de Nice une destination inoubliable sur la Côte d'Azur.",
          image: "/arrondissementsdetails/nice.jpg",
        },
        {
          id: 3,
          nom: "Saint-Paul-de-Vence",
          détail: "Saint-Paul-de-Vence, joyau médiéval perché sur une colline, émerveille les visiteurs par son charme intemporel et son ambiance artistique. Cette petite ville provençale est réputée pour ses ruelles pavées, ses galeries d'art et son atmosphère préservée. Saint-Paul-de-Vence incarne le mariage parfait entre l'histoire médiévale et la créativité contemporaine.",
          environnement: "Située au sommet d'une colline, Saint-Paul-de-Vence offre des vues panoramiques sur les collines verdoyantes de la Provence. Les remparts médiévaux qui entourent la ville ajoutent une touche d'authenticité, tandis que les oliveraies et les champs de lavande environnants créent un cadre paisible.",
          monuments: "Les rues étroites de Saint-Paul-de-Vence dévoilent des trésors architecturaux, notamment la Collégiale Saint-Paul, une église datant du XVIe siècle, et la porte de Vence, vestige des fortifications médiévales. Les fontaines pittoresques et les places ombragées ajoutent à l'ambiance médiévale de la ville.",
          monuments2: "Des galeries d'art renommées, telles que la Fondation Maeght, sont dispersées dans la ville, attirant des artistes du monde entier. Les sculptures contemporaines et les expositions artistiques font partie intégrante de l'expérience culturelle de Saint-Paul-de-Vence.",
          espaces: "Bien que petite, la ville propose des espaces charmants tels que le jardin de la Maison de la Tour, offrant une vue magnifique sur la campagne environnante. Les terrasses des cafés et des restaurants sont des endroits prisés pour déguster la cuisine provençale tout en profitant du panorama.",
          conclusion: "Saint-Paul-de-Vence, avec son caractère préservé et son attrait artistique, est devenue une destination de choix pour les amateurs d'art et les voyageurs en quête d'authenticité. Les hôtels de charme au cœur de la vieille ville offrent une expérience unique. Parmi les incontournables, citons les galeries d'art, les ruelles médiévales et les vues imprenables depuis les remparts, faisant de Saint-Paul-de-Vence un trésor caché de la Provence.",
          image: "/arrondissementsdetails/spdv.jpg",
        },
        {
          id: 4,
          nom: "Cannes",
          détail: "Cannes, située sur la magnifique Côte d'Azur, séduit par son glamour, ses plages dorées et son atmosphère effervescente. Cette ville balnéaire renommée est célèbre pour son festival du film, ses boutiques de luxe et son élégance méditerranéenne. Cannes incarne le mariage parfait entre le style de vie chic et le charme côtier.",
          environnement: "Nichée entre le massif de l'Esterel et la mer Méditerranée, Cannes offre un cadre naturel spectaculaire. Les plages de sable fin, comme la Croisette, invitent à la détente au soleil, tandis que les eaux azurées offrent des reflets éblouissants. La Promenade de la Croisette, bordée de palmiers, est le lieu de prédilection pour une élégante flânerie en bord de mer.",
          monuments: "Cannes dévoile son riche patrimoine à travers des lieux emblématiques tels que le Palais des Festivals et des Congrès, qui accueille chaque année le prestigieux Festival de Cannes. La vieille ville, Le Suquet, avec ses ruelles pavées et sa tour médiévale, offre une plongée dans l'histoire de la ville.",
          monuments2: "Des édifices Belle Époque, tels que l'Hôtel Martinez et l'Hôtel Carlton, ajoutent une touche de glamour à l'architecture de la ville. Le Vieux Port, animé par des yachts luxueux, crée une atmosphère sophistiquée.",
          espaces: "Malgré son ambiance cosmopolite, Cannes propose des espaces verts paisibles comme le parc de la Roseraie, offrant une échappée tranquille du tumulte de la ville. Les îles de Lérins, accessibles en bateau, offrent des retraites naturelles avec des vues panoramiques sur la baie.",
          conclusion: "Cannes, avec son mélange unique de luxe, de culture et de nature, attire une clientèle internationale en quête d'élégance méditerranéenne. Les hôtels de renom le long de la Croisette offrent un hébergement de premier ordre. Parmi les incontournables, citons les festivals de renommée mondiale, les plages exclusives et la vie nocturne animée, faisant de Cannes une destination de premier plan sur la Côte d'Azur.",
          image: "/arrondissementsdetails/cannes.jpg",
        },

        // Ajoutez d'autres arrondissements ici
      ],
    };
  },
  computed: {
    selectedArrondissement() {
      const id = parseInt(this.$route.params.id);
      return this.arrondissements.find(
          (arrondissement) => arrondissement.id === id
      );
    }
    ,
  }
  ,
};
</script>

<style scoped>
.img-fluid {
  border-radius: 25px;
}
</style>
