<template>
  <section class="section bg-light text-white py-5">
    <div class="container-fluid col-md-10 text-center">
      <h1 class="display-4 text-dark">Destinations populaires</h1>
      <p class="subtitle text-dark">Nous proposons des séjours de qualité à nos voyageurs dans Paris et sur la Côte d'Azur</p>
    </div>
    <Carousel :itemsToShow="itemsToShow" :wrapAround="true" :transition="500">
      <Slide v-for="slide in 4" :key="slide">
        <div class="carousel__item mt-4">
          <div class="card text-bg-dark">
            <div class="image-container">
              <img :src="images[slide]" class="card-img" :alt="'Destination ' + slide">
              <router-link :to="'/destinations/' + slide">
                <div class="zoom-overlay">
                  <span class="zoom-text">{{ destinations[slide - 1] }} <i class="fa-solid fa-magnifying-glass fa-2xs"></i></span>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </Slide>
    </Carousel>
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Zone',
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      itemsToShow: this.calculateItemsToShow(),
      images: [
        "",
        "/arrondissements/paris.jpg",
        "/arrondissements/nice.jpg",
        "/arrondissements/SPDV.jpg",
        "/arrondissements/canne.jpg",
      ],
      destinations: ['Paris', 'Nice', 'Saint-Paul-de-Vence', 'Cannes'],
    }
  },
  methods: {
    calculateItemsToShow() {
      const breakpoints = [
        { width: 767, items: 1.1 },
        { width: 1023, items: 2.1 },
        { width: Infinity, items: 2.7 },
      ];

      const screenWidth = window.innerWidth;
      const matchedBreakpoint = breakpoints.find(bp => screenWidth <= bp.width);

      return matchedBreakpoint.items;
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.itemsToShow = this.calculateItemsToShow();
    });
  },
})
</script>

<style scoped>
/* Ajoutez vos styles CSS personnalisés ici */
.display-4 {
  font-weight: 600;
}

.subtitle {
  font-size: 18px;
  font-weight: 300;
}

.card {
  border: none;
  border-radius: 15px;
}

.image-container {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}

.card-img {
  display: block;
  max-width: 100%;
  height: auto;
  transition: transform 0.3s ease;
  border-radius: 15px;
}

.zoom-overlay {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: white;
  padding: 5px 10px;
  border-radius: 15px;
  opacity: 1;
}

.zoom-overlay:hover {
  transform: scale(1.05);
  transition: transform .1s;
}

.zoom-text {
  display: block;
  text-align: center;
  color: black;
  font-size: 18px;
  font-weight: 500;
  height: 2rem;
  width: 13rem;
}

.carousel__item {
  margin: 0 1rem;
}

@media (max-width: 767px) {
  .carousel__item {
    margin: 0 0.5rem;
  }
}

.section {
  margin-bottom: 2rem;
}
</style>
