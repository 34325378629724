<template>
  <div id="nav">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="d-flex align-items-center"> <!-- Nouveau div ajouté -->
        <router-link to="/" class="navbar-brand">
          <img src="../assets/Logo-OpenFlats-Blanc.png" class="card-img" alt="logo Openflats" style="width: 10rem;">
        </router-link>
      </div>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/logements">
              <button class="btn custom-btn" type="submit">Nos logements</button>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/proprietaire">
              <button class="btn custom-btn" type="submit">A propos</button>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/proprietaire">
              <button class="btn custom-btn" type="submit">Nous contacter</button>
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>


<script>
export default {
  name: "NavBar",
};
</script>

<style scoped>
#nav {
  background: #0081B5;
}

#nav {
  text-align: center;
}

#nav a {
  font-weight: bold;
  color: white;
}

span {
  color: white;
}

img {
  width: 10rem;
}

p {
  font-family: Helvetica, sans-serif;
}

.custom-btn {
  color: white;
  border-color: white;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
  margin: 1rem;
}

.custom-btn:hover {
  background-color: white;
  color: #0081B5;
  border-color: white;
}

#nav a {
  color: white;
  font-weight: bold;
  border-color: white;
  margin-left: 1rem;
  margin-right: 1rem;
}

#nav a.router-link-exact-active {
  background: none;
}
</style>
