<template>
  <section class="section bg-light text-dark py-5">
    <div class="container">
      <div class="text-center">
        <h1 class="display-4">Ce que nos locataires disent</h1>
        <p class="subtitle">Chez Openflats, nous faisons tout pour rendre chaque séjour agréable</p>
      </div>
      <div class="row">
        <div v-for="review in reviews" :key="review.id" class="col-md-4 mb-4">
          <div class="card">
            <div class="card-body">
              <blockquote class="blockquote mb-0">
                <p>{{ review.text }}</p>
                <footer class="blockquote-footer">{{ review.name }}, <cite title="Source Title">{{ review.date }}</cite>
                </footer>
                <div class="etoiles">
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                </div>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import {defineComponent} from 'vue';

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Avis',
  data() {
    return {
      reviews: [
        {
          id: 1,
          name: "Léonie",
          text: "Tout s'est très bien passé. Nous nous sommes regalés. La maison est parfaite, très joliment décorée. Et correspond parfaitement aux photos. L'arrivée et le départ autonome sont un vrai plus.",
          date: "Nice",
        },
        {
          id: 2,
          name: "Adilene",
          text: "The home was clean and all utilities functioned appropriately. The bedroom are offers sufficient room for luggage. A small market is located within 200m and various restaurants and shops nearby.",
          date: "Paris",
        },
        {
          id: 3,
          name: "Rosendo",
          text: "Alojamiento buen situado; pero con una pésima comunicación. Dijeron que teníamos acceso a documentos online para el uso de la propiedad y nunca pudimos acceder.",
          date: "Paris",
        },
      ],
    };
  },
});
</script>

<style scoped>
.display-4 {
  font-weight: 600;
}

.subtitle {
  font-size: 18px;
  font-weight: 300;
}

.card {
  border: none;
  border-radius: 10px;
}

section {
  margin-bottom: 2rem;
}
</style>
